<template>
	<div class="container auth-form p-4">
		<div class="row">
			<div class="col-6">
				<h1 class="header-text-font text-left-color">Extra authentication is needed to continue...</h1>
				<p class="paragraph-text-font text-left-color">Your account has two-factor authentication enabled. Enter your authentication code in the field below to continue.</p>
			</div>
			<div class="col-1"></div>
			<div class="col-5">
				<div class="d-grid mb-3 text-center">
					<h1 class="header-text-font text-right-color">Two-Factor Authentication</h1>
					<p class="paragraph-text-font text-right-color">Open Authenticator on your mobile device in order to get your auth code.</p>
				</div>
				<two-f-a-form :auth-key="$route.params.auth_key" />
			</div>
		</div>
	</div>
</template>

<script>
import TwoFAForm from "../components/TwoFAForm";
export default {
	name: "TwoFA",
	components: {TwoFAForm}
}
</script>

<style>

#app {
	background: linear-gradient(
		to right,
		#141850 0%,
		#303179 55%,
		#fae5df 0%,
		#fae5df 0%
	) 0 0 / cover;
}

</style>