<template>
	<form @submit.prevent="submit">
		<div class="mb-3">
			<input v-model="code"
			       id="email"
			       class="input-field"
			       type="text"
			       placeholder="Enter your auth code"
			       required>
			<div class="mt-2">
				<small v-if="error"
				       class="form-text mt-2 font-weight-bold text-danger text-left">
					<img src="../assets/error.png" height="15" width="15" alt="Error">
					{{ error }}
				</small>
			</div>
		</div>

		<button v-bind:disabled="error"
		        type="submit"
		        class="btn btn-primary btn-block">
			<span v-if="loading" class="spinner-border spinner-border-sm"></span>
			{{ submitButtonText }}
		</button>

		<button @click="recoverAccount" type="button" class="btn btn-danger btn-block">
			Recover my account
		</button>
	</form>
</template>

<script>

import auth from '../auth/index'

export default {
	name: "TwoFAForm",
	computed: {
		submitButtonText() {
			return this.loading ?
				'Loading...' :
				'Continue'
		}
	},
	props: {
		authKey: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			code: null,
			loading: false,
			error: null
		}
	},
	methods: {
		recoverAccount() {
			this.$router.push({
				name: "TwoFARecover",
				params: {
					auth_key: this.authKey
				}
			})
		},
		submit() {
			this.loading = true
			this.error = null

			auth.pass2FA({
				key: this.authKey,
				code: this.code
			})
				.then(data => {
					this.loading = false
					this.error = null

					this.$store.commit("setUser", data)
					localStorage.setItem('token', data.token)
					this.$router.push({ name: "Dashboard" })
				})
				.catch(error => {
					this.loading = false
					this.error = error.response.data.message
				})
		}
	}
}
</script>

<style scoped>

</style>